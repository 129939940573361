import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';

export const CREATE_ORDER_OPTIONS = {
  pharmacyDelivery: 'Pharmacy Delivery',
  telehealthRequest: 'Telehealth Request'
};

export const ALLOWED_TELEHEALTH_LOCATION_STATUSES = [1, 5, 6];

@Component({
  selector: 'app-create-order-options',
  templateUrl: './create-order-options.component.html',
  styleUrls: ['./create-order-options.component.scss']
})
export class CreateOrderOptionsComponent implements OnInit {
  options: string[] = [];
  constructor(private dialogRef: MatDialogRef<CreateOrderOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private jwtAuth: JwtAuthService) { }

  ngOnInit(): void {
    this.options = this.initializeOptions();

    if (this.options.length === 1) {
      this.closeDialog(this.options[0]);
    }
  }

  initializeOptions(): string[] {
    const locationStatus = this.jwtAuth.getUser().location?.locationstatus;
    const options = [];

    if (ALLOWED_TELEHEALTH_LOCATION_STATUSES.includes(locationStatus)) {
      options.push(CREATE_ORDER_OPTIONS.telehealthRequest);
    }

    options.push(CREATE_ORDER_OPTIONS.pharmacyDelivery);

    return options;
  }

  closeDialog(option: string = '') {
    this.dialogRef.close(option);
  }

}
