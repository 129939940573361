import { Injectable } from '@angular/core';
import { JwtAuthService } from './auth/jwt-auth.service';
import { PortalSettingsService } from './portal-settings.service';
import { IMenuItem } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class RouteProtectionService {
  constructor(
    private auth: JwtAuthService,
    private portalSettingService: PortalSettingsService
  ) {}

  hiddenNavigationKeys(): string[] | undefined {
    return this.portalSettingService.settings.hidden_navigation_keys?.[
      this.auth.role
    ];
  }

  cannotAccessRoute(path: string) {
    return !this.canAccessRoute(path);
  }

  canAccessRoute(path: string) {
    const keys = this.hiddenNavigationKeys()
    if (typeof keys === 'undefined') return true;
    return Boolean(
      keys?.some(
        (partialPath) => !path.includes(partialPath)
      )
    );
  }

  filterMenu(menu: IMenuItem[]) {
    return menu.filter(
      (item) => !this.hiddenNavigationKeys()?.includes(item.state)
    );
  }
}
