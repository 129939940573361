<div class="header">
    <h2>Create Order</h2>
    <mat-icon (click)="closeDialog()">close</mat-icon>
</div>

<div mat-dialog-content class="grid-container" *ngIf="!loading">
    <mat-stepper #createOrderStepper [linear]="true" *ngIf="!isOrderCreated;else showOrderCreatedSuccessfully"
        [disableRipple]="true">

        <!-- Step 1: Select Pharmacy -->
        <mat-step *ngIf="isAdmin || isBusiness" label="Select Pharmacy" errorMessage="error"
            [stepControl]="selectPharmacyStepControl">
            <div class="other-container">
                <div class="other-header">
                    <h2>{{currentPharmacy?.locationname || "Select a Pharmacy"}}</h2>
                    <button (click)="changePharmacy()" mat-stroked-button class="mm-bg-accent mm-text-white">
                        {{!currentPharmacy?.locationname ? 'Select Pharmacy':'Change Pharmacy'}}</button>
                </div>
            </div>
        </mat-step>
        <!-- Step 2: Customer Details -->
        <mat-step label="Customer Details" [stepControl]="groupedAllCustomerForms">
            <div class="details-container">
                <form [formGroup]="customerDetailsForm" #formDirective="ngForm">
                    <div>
                        <h2>Customer Details</h2>
                        <mat-divider></mat-divider>
                        <div class="contact-container">
                            <mat-form-field appearance="outline" style="grid-area:'firstName'">
                                <mat-label>First Name</mat-label>
                                <input type="text" matInput formControlName="firstName"
                                    [required]="customerDetailsForm.get('firstName').hasError('required')">
                                <mat-error *ngIf="isRequiredAndTouched(customerDetailsForm.get('firstName'))">
                                    First Name is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="grid-area:'lastName'">
                                <mat-label>Last Name</mat-label>
                                <input type="text" matInput formControlName="lastName"
                                    [required]="customerDetailsForm.get('lastName').hasError('required')">
                                <mat-error *ngIf="isRequiredAndTouched(customerDetailsForm.get('lastName'))">
                                    Last Name is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="grid-area:'email'">
                                <mat-label>Email</mat-label>
                                <input matInput formControlName="email" [matAutocomplete]="userSearch"
                                    placeholder="Search for a user or Enter an email address"
                                    [required]="customerDetailsForm.get('email').hasError('required')">

                                <mat-spinner *ngIf="searchingUser" matSuffix diameter="25"></mat-spinner>

                                <mat-autocomplete [autoActiveFirstOption]="true" (optionSelected)="selectUser($event)"
                                    #userSearch="matAutocomplete" class="custom-scroll-bar">
                                    <mat-option [value]="getCustomerDetailsFormValue('email')"><span>Add New
                                            User</span>
                                        ({{ getCustomerDetailsFormValue('email') }})</mat-option>
                                    <mat-option *ngFor="let user of userSearchResults" [value]="user">
                                        <span>{{user.username}}: {{user.firstname}} {{user.lastname}}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="isRequiredAndTouched(customerDetailsForm.get('email'))">
                                    Email is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="grid-area:'mobile'">
                                <mat-label>Mobile</mat-label>
                                <input type="tel" matInput formControlName="mobile"
                                    [required]="customerDetailsForm.get('mobile').hasError('required')">
                                <mat-error *ngIf="isRequiredAndTouched(customerDetailsForm.get('lastName'))">
                                    Mobile is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <form [formGroup]="addressDetailsForm" #formDirective="ngForm">
                    <div matTooltip="Please complete the customer details before proceeding with address details."
                        [matTooltipDisabled]="customerDetailsForm.valid">
                        <h2>Address Details</h2>
                        <mat-divider></mat-divider>
                        <div class="address-container">

                            <mat-form-field appearance="outline" style="grid-area:'streetNumber'">
                                <mat-label>Street Number</mat-label>
                                <input matInput formControlName="streetNumber"
                                    [required]="addressDetailsForm.get('streetNumber').hasError('required')">
                                <mat-error *ngIf="isRequiredAndTouched(addressDetailsForm.get('streetNumber'))">
                                    Street Number is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="grid-area:'streetName'">
                                <mat-label>Street Name</mat-label>
                                <input matInput formControlName="streetName"
                                    [required]="addressDetailsForm.get('streetName').hasError('required')">
                                <mat-error *ngIf="isRequiredAndTouched(addressDetailsForm.get('streetName'))">
                                    Street Name is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="grid-area:'address'">
                                <mat-label>Search Address</mat-label>
                                <input id="address-input" matInput ngx-google-places-autocomplete [options]="options"
                                    #placesRef (onAddressChange)="handleAddressChange($event)" formControlName="address"
                                    [required]="addressDetailsForm.get('address').hasError('required')"
                                    placeholder="Search for an address">
                                <mat-error *ngIf="isRequiredAndTouched(addressDetailsForm.get('address'))">
                                    Address is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="grid-area:'unit'">
                                <mat-label>Unit</mat-label>
                                <input matInput formControlName="unit"
                                    [required]="addressDetailsForm.get('unit').hasError('required')">
                                <mat-error *ngIf="isRequiredAndTouched(addressDetailsForm.get('unit'))">
                                    Unit is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="grid-area:'city'">
                                <mat-label>City</mat-label>
                                <input matInput formControlName="city"
                                    [required]="addressDetailsForm.get('city').hasError('required')">
                                <mat-error *ngIf="isRequiredAndTouched(addressDetailsForm.get('city'))">
                                    City is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="grid-area:'state'">
                                <mat-label>State</mat-label>
                                <input matInput formControlName="state"
                                    [required]="addressDetailsForm.get('state').hasError('required')">
                                <mat-error *ngIf="isRequiredAndTouched(addressDetailsForm.get('state'))">
                                    State is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="grid-area:'postcode'">
                                <mat-label>Postcode</mat-label>
                                <input matInput formControlName="postcode"
                                    [required]="addressDetailsForm.get('postcode').hasError('required')">
                                <mat-error *ngIf="isRequiredAndTouched(addressDetailsForm.get('postcode'))">
                                    Postcode is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <form [formGroup]="deliveryDetailsForm" #formDirective="ngForm">
                    <div style="margin-top: -25px;">
                        <div class="delivery_type-container">
                            <h2>Delivery Type</h2>
                            <div class='delivery_selection-container'>
                                <input id="toggle-on" class="toggle toggle-left" name="toggle" value="delivery"
                                    type="radio" checked [formControl]="deliveryType">
                                <label for="toggle-on" class="btn">Delivery</label>
                                <input id="toggle-off" class="toggle toggle-right" name="toggle" value="pick up"
                                    type="radio" [formControl]="deliveryType">
                                <label for="toggle-off" class="btn">Pick Up</label>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div *ngIf='deliveryType.value=="delivery"; else pickupContainer' class="delivery-container"
                            matTooltip="Please complete the address details before proceeding with delivery information."
                            [matTooltipDisabled]="deliveryDetailsForm.enabled">
                            <mat-checkbox (change)="addDeliveryAddress()" formControlName="sameAsCustomerAddress">
                                Same as customer's address
                            </mat-checkbox>
                            <mat-form-field *ngIf="!getDeliveryDetailsFormValue('sameAsCustomerAddress')"
                                appearance="outline" style="grid-area:'address'">
                                <mat-label>New Delivery Address</mat-label>
                                <input id="delivery_address" matInput ngx-google-places-autocomplete [options]="options"
                                    #placesRef (onAddressChange)="setNewDeliveryAddress($event)"
                                    formControlName="deliveryAddress"
                                    [required]="deliveryDetailsForm.get('deliveryAddress').hasError('required')"
                                    placeholder="Search for a new delivery address">
                                <mat-error *ngIf="isRequiredAndTouched(deliveryDetailsForm.get('deliveryAddress'))">
                                    New delivery address is required
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field
                                matTooltip="Please choose either 'Same as customer's address' or 'Enter a new delivery address' to load delivery partners."
                                [matTooltipDisabled]="deliveryDetailsForm.disabled || deliveryPartners.length>0"
                                appearance="outline" style="grid-area:'partners'">
                                <mat-label>Delivery Partner</mat-label>
                                <mat-select (selectionChange)="setDeliveryFee($event)" formControlName="deliveryPartner"
                                    [required]="deliveryDetailsForm.get('deliveryPartner').hasError('required')">
                                    <!-- <mat-option disabled selected value="">Select a Delivery Partner</mat-option> -->
                                    <mat-option *ngFor="let partner of deliveryPartners" [value]="partner.partnername">
                                        {{partner.deliverytext}} - {{partner.finaldeliveryfee}}</mat-option>
                                </mat-select>
                                <mat-spinner matSuffix *ngIf="loadingDelivery" diameter="25"></mat-spinner>
                                <mat-error *ngIf="isRequiredAndTouched(deliveryDetailsForm.get('deliveryPartner'))">
                                    Delivery Partner is required
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" style="grid-area:'instructions'">
                                <mat-label>Delivery Instruction</mat-label>
                                <mat-select formControlName="deliveryInstruction"
                                    [required]="deliveryDetailsForm.get('deliveryInstruction').hasError('required')">
                                    <!-- <mat-option disabled selected hidden value>Delivery Instruction</mat-option> -->
                                    <mat-option *ngFor="let instruction of deliveryInstructions" [value]="instruction">
                                        {{instruction}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="isRequiredAndTouched(deliveryDetailsForm.get('deliveryInstruction'))">
                                    Delivery instruction is required
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" style="grid-area:'additional'">
                                <mat-label>Additional Delivery Instructions</mat-label>
                                <textarea matInput no-resize formControlName="additionalDeliveryInstructions"
                                    [required]="deliveryDetailsForm.get('additionalDeliveryInstructions').hasError('required')"></textarea>
                                <mat-error
                                    *ngIf="isRequiredAndTouched(deliveryDetailsForm.get('additionalDeliveryInstructions'))">Additional
                                    Delivery Instructions is required</mat-error>
                            </mat-form-field>
                        </div>
                        <ng-template #pickupContainer>
                            <form [formGroup]="pickUpDetailsForm" #formDirective="ngForm">
                                <div class="pickup-container"
                                    matTooltip="Please complete the address details before proceeding with pick up information."
                                    [matTooltipDisabled]="pickUpDetailsForm.enabled">
                                    <h3 style="grid-area:'heading';">Preferred Pick Up Time</h3>
                                    <mat-form-field appearance="outline" style="grid-area:'date';">
                                        <mat-label>Date</mat-label>
                                        <input matInput placeholder="DD/MM/YYYY" [matDatepicker]="picker"
                                            [min]="startDate" formControlName="pickUpDate"
                                            [required]="pickUpDetailsForm.get('pickUpDate').hasError('required')">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
                                        <mat-error *ngIf="isRequiredAndTouched(pickUpDetailsForm.get('pickUpDate'))">
                                            Pick Up Date is required</mat-error>
                                    </mat-form-field>
                                    <mat-form-field class="time-container" appearance="outline"
                                        style="grid-area:'time';">
                                        <mat-label for="time">Time</mat-label>
                                        <input matInput type="time" formControlName="pickUpTime"
                                            [required]="pickUpDetailsForm.get('pickUpTime').hasError('required')">
                                        <mat-error *ngIf="isRequiredAndTouched(pickUpDetailsForm.get('pickUpTime'))">
                                            Pick Up Time is required</mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" style="grid-area:'notes';">
                                        <mat-label>Pick Up Notes</mat-label>
                                        <textarea matInput no-resize formControlName="pickUpNotes"
                                            [required]="pickUpDetailsForm.get('pickUpNotes').hasError('required')"></textarea>
                                        <mat-error *ngIf="isRequiredAndTouched(pickUpDetailsForm.get('pickUpNotes'))">
                                            Pick Up Notes is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </form>
                        </ng-template>
                    </div>
                </form>
            </div>
        </mat-step>
        <!-- Step 3: Add Items -->
        <mat-step label="Add Items" [stepControl]="addItemsStepControl">
            <div class="cart-container">
                <div class="add_item-container">
                    <!-- <button type="button" class="addItemBtn">+</button> -->
                    <select class="mm-select" [(ngModel)]="addItemType">
                        <option value="otc">OTC Item</option>
                        <option value="paper">Script Item</option>
                        <!-- <option value="script">Script Token</option> -->
                    </select>
                    <div *ngIf="addItemType=='otc'">
                        <input #otcSearchInput [matAutocomplete]="otcItems" class="mm-text-input"
                            placeholder="Search for an OTC item..." [formControl]="addOTCItem">
                        <mat-autocomplete [autoActiveFirstOption]="true" (optionSelected)="addToCart($event)"
                            #otcItems="matAutocomplete" class="custom-scroll-bar">
                            <mat-progress-bar *ngIf="searchingItem" mode="indeterminate"></mat-progress-bar>
                            <mat-option [value]="addOTCItem.value"><span>Add New Item</span> ({{ addOTCItem.value
                                }})</mat-option>
                            <mat-option *ngFor="let state of filteredOtcItemResults | async" [value]="state">
                                <img class="example-option-img" aria-hidden [src]="state.PhotoID_s3" height="25">
                                <span>{{state.drug}}</span> |
                                <small>Price: {{state.price}}</small>
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div *ngIf="addItemType=='paper'">
                        <input #scriptItemInput [matAutocomplete]="scriptItems" class="mm-text-input"
                            placeholder="Add a script item" [formControl]="addScriptItem">
                        <mat-autocomplete [autoActiveFirstOption]="true" (optionSelected)="addToCart($event)"
                            #scriptItems="matAutocomplete" class="custom-scroll-bar">
                            <mat-option [value]="addScriptItem.value"><span>Add New Item</span> ({{
                                addScriptItem.value
                                }})</mat-option>
                        </mat-autocomplete>
                    </div>
                    <div *ngIf="addItemType=='script'">
                        <input class="mm-text-input" placeholder="Enter an eScript Token"
                            [formControl]="addScriptToken">
                    </div>
                    <mat-spinner *ngIf='searchingItem' diameter="25"></mat-spinner>
                </div>
                <ng-container *ngIf="items.length>0">
                    <table mat-table [dataSource]="items" class="items-table">
                        <ng-container matColumnDef="Image">
                            <th mat-header-cell *matHeaderCellDef></th>

                            <td mat-cell *matCellDef="let element">
                                <img src="{{element.image}}" style="height: 40px;width: 40px;" />
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ItemDetails">
                            <th mat-header-cell *matHeaderCellDef>Item Details</th>

                            <td mat-cell *matCellDef="let element; let i = index;">
                                <!-- <div *ngIf="editDetails==1" class="table-cell-value">
                        <input class="mm-text-input" [(ngModel)]="items[i].drugname">
                </div> -->
                                <div class="table-cell-value">{{element.details}}</div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="CustomerPrice">
                            <th mat-header-cell *matHeaderCellDef>Customer Price</th>
                            <td mat-cell *matCellDef="let element; let i = index;">
                                <!-- <div *ngIf="element.isScript; else nonEditableCustomerPrice">
                                    <input  [(ngModel)]="element.unitPrice"[attr.data-index]="i"
                                        [attr.data-column]="'CustomerPrice'" type="number" [min]="0"
                                        class="mm-text-input" />
                                </div> -->
                                <!-- <ng-template #nonEditableCustomerPrice> -->
                                {{calculateCustomerPrice(element) | currency }}
                                <!-- </ng-template> -->
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="UnitPrice">
                            <th mat-header-cell *matHeaderCellDef>Unit Price</th>
                            <td mat-cell *matCellDef="let element; let i = index;">
                                <div *ngIf="element.isScript; else nonEditableUnitPrice">
                                    <input [(ngModel)]="element.unitPrice" [attr.data-index]="i"
                                        [attr.data-column]="'UnitPrice'" type="number" [min]="0"
                                        class="mm-text-input" />
                                </div>
                                <ng-template #nonEditableUnitPrice>
                                    {{ element.unitPrice | currency }}
                                </ng-template>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="QTY">
                            <th mat-header-cell *matHeaderCellDef>QTY</th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                <div>
                                    <button *ngIf="element.quantity==1" color="primary" (click)="deleteItem(element)"
                                        mat-icon-button>
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                    <button *ngIf="element.quantity!=1"
                                        [disabled]="element.quantity == 1 || element.quantity == '' || element.quantity === null"
                                        color="primary" (click)="onItemChangeQty(i,'-')" mat-icon-button>
                                        <mat-icon>remove_circle</mat-icon>
                                    </button>
                                    {{element.quantity ? element.quantity : '1'}}
                                    <button color="primary" (click)="onItemChangeQty(i,'+')" mat-icon-button>
                                        <mat-icon>add_circle</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Amount">
                            <th mat-header-cell *matHeaderCellDef>Price</th>
                            <td mat-cell *matCellDef="let element">{{calculateTotalPrice(element) | currency }}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </ng-container>
            </div>
        </mat-step>
        <!-- Step 4: Review Order -->
        <mat-step label="Review Order">
            <div class="review-order-container">
                <div *ngIf="isAdmin || isBusiness">
                    <h2 class="pharmacy-location-name">{{currentPharmacy?.locationname || "Select a Pharmacy"}}</h2>
                    <mat-divider></mat-divider>
                </div>
                <div class="pharmacy-customer-details">
                    <!-- Customer Details Tile -->
                    <div class="details-tile">
                        <h1 class="mm-text-accent">Customer Details</h1>
                        <p>{{getCustomerDetailsFormValue('firstName')}} {{getCustomerDetailsFormValue('lastName')}}
                        </p>
                        <p>{{getCustomerDetailsFormValue('email')}}</p>
                        <p>{{getCustomerDetailsFormValue('mobile')}}</p>
                        {{getAddressDetailsFormValue('unit') ? getAddressDetailsFormValue('unit') + '/' :''}}
                        {{getAddressDetailsFormValue('streetNumber')}}
                        {{getAddressDetailsFormValue('streetName')}}
                        <p>
                            {{getAddressDetailsFormValue('city') ? getAddressDetailsFormValue('city') + ', ' :''}}
                            {{getAddressDetailsFormValue('state')?getAddressDetailsFormValue('state') + ', ':''}}
                            {{getAddressDetailsFormValue('postcode') || ''}}
                        </p>
                        <p>
                            <span class="mm-text-accent  mm-text-bold">Delivery
                                Type:&nbsp;</span>
                            <span>{{deliveryType.value==='delivery'?getDeliveryDetailsFormValue('deliveryPartner'):'PickUp'}}</span>
                        </p>
                        <p>
                            <span class="mm-text-accent  mm-text-bold">Delivery
                                Instructions:&nbsp;</span>
                            <span>{{getDeliveryDetailsFormValue('deliveryInstruction')}}</span>
                        </p>
                    </div>
                    <!-- Additional Delivery Instructions -->
                    <div class="details-tile">
                        <h1 class="mm-text-accent">Additional Delivery Instructions</h1>
                        <p>
                            {{getDeliveryDetailsFormValue('additionalDeliveryInstructions')
                            || 'No additional instructions'}}
                        </p>
                    </div>
                </div>

                <table mat-table [dataSource]="items" class="items-table">
                    <ng-container matColumnDef="Image">
                        <th mat-header-cell *matHeaderCellDef></th>

                        <td mat-cell *matCellDef="let element">
                            <img src="{{element.image}}" style="height: 40px;width: 40px;" />
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="ItemDetails">
                        <th mat-header-cell *matHeaderCellDef>Item Details</th>

                        <td mat-cell *matCellDef="let element; let i = index;">
                            <!-- <div *ngIf="editDetails==1" class="table-cell-value">
                    <input class="mm-text-input" [(ngModel)]="items[i].drugname">
            </div> -->
                            <div class="table-cell-value">{{element.details}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="CustomerPrice">
                        <th mat-header-cell *matHeaderCellDef>Customer Price</th>
                        <td mat-cell *matCellDef="let element">{{calculateCustomerPrice(element) | currency }}</td>
                    </ng-container>

                    <ng-container matColumnDef="UnitPrice">
                        <th mat-header-cell *matHeaderCellDef>Unit Price</th>

                        <td mat-cell *matCellDef="let element; let i = index;">
                            <div>{{ element.unitPrice | currency}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="QTY">
                        <th mat-header-cell *matHeaderCellDef>QTY</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            {{element.quantity ? element.quantity : '1'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Amount">
                        <th mat-header-cell *matHeaderCellDef>Price</th>
                        <td mat-cell *matCellDef="let element">{{calculateTotalPrice(element) | currency }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <div class="confirmation-container">
                    <!-- <div class="promocode_input-container">
                    <span class="promocode-title">Promo Code</span>
                    <input class="mm-text-input" placeholder="Enter promotion" [(ngModel)]="promocode">
                </div> -->
                    <div class="price_details-container">
                        <div id="sub_total-container">
                            <span class="price-title">Sub Total</span>
                            <span>{{calculateSubtotal() | currency}}</span>
                        </div>
                        <div id="delivery_fee-container">
                            <span class="price-title">Delivery Fee</span>
                            <span>{{calculateDeliveryFee() | currency}}</span>
                            <!-- <ng-template #admin>
                                <span class="input-symbol-dollar">
                                    <input class="mm-text-input" type="number" min="0" [(ngModel)]="deliveryFee">
                                </span>
                            </ng-template> -->
                        </div>
                        <div id="service_fee-container">
                            <span class="price-title">Service Fee</span>
                            <span>{{calculateServiceFee() | currency}}</span>
                            <!-- <ng-template #serviceInput>
                                <span class="input-symbol-dollar">
                                    <input class="mm-text-input" type="number" min="0" [(ngModel)]="serviceFee">
                                </span>
                            </ng-template> -->
                        </div>
                        <div id="total-container">
                            <span class="price-title">Total</span>
                            <span>{{calculateTotal() | currency}}</span>
                        </div>
                    </div>
                    <!-- <div class="button-container">
                        <button mat-flat-button class="mm-bg-primary mm-text-white" (click)="placeOrder()">Confirm and
                            Create
                            Order</button>
                    </div> -->
                </div>
            </div>
        </mat-step>
        <ng-template matStepperIcon="edit" let-index="index">
            <div
                style="background:#757575;position: relative;border-radius: 50%;height: 24px;width: 24px;display:grid;place-items:center;">
                {{index+1}}</div>
        </ng-template>
    </mat-stepper>

    <ng-template #showOrderCreatedSuccessfully>
        <div class="order-created-message-container">
            <div class="order-created-message">
                <h3>Order Confirmed!</h3>
                <p>Your customer will receive a payment link shortly. Please verify that the item totals are accurate.
                </p>
                <button mat-flat-button color="primary" (click)="viewOrder()">View Order</button>
            </div>
        </div>
    </ng-template>

</div>

<mat-dialog-actions align="end" *ngIf="!loading">
    <ng-container *ngIf="!isOrderCreated;else showOrderCreatedButtons">
        <button mat-flat-button color="primary" (click)="prevStep()"
            [disabled]="stepper?.selectedIndex===0">Back</button>
        <button mat-flat-button color="primary" (click)="nextStep()"
            [disabled]="stepper?.selectedIndex===lastStepIndex">Next</button>
        <button mat-flat-button [matTooltip]="canEnableCreateOrderButton.reason" color="primary" (click)="placeOrder()"
            [disabled]="canEnableCreateOrderButton.canEnable">Review and Create Order</button>
    </ng-container>
    <ng-template #showOrderCreatedButtons>
        <button mat-flat-button color="primary" (click)="closeDialog()">Close</button>
        <button mat-stroked-button class="mm-bg-accent mm-text-white" (click)="createAnotherOrder()">Create Another
            Order</button>
    </ng-template>
</mat-dialog-actions>

<mat-spinner id='loading-spinner' *ngIf="loading" diameter="50"></mat-spinner>