<mat-drawer-container class="item-drawer-container" hasBackdrop="true"
  (click)="$event.stopPropagation(); showMoreActions=false;">
  <mat-drawer #drawer mode="over" position="end" class="item-drawer custom-scroll-bar">
    <div class="item-drawer-inner">
      <div class="item-drawer-image" [ngStyle]="{'background-image': getItemImage()}"></div>
      <mat-divider class="mt-5"></mat-divider>
      <div class="mt-5 mat-h3 mm-fw-500 item-drawer-name" [title]="getItemName('title')">{{ getItemName('no-title') }}
      </div>
      <!-- <div class="mat-h2">{{ getDrawerContent().ItemName }}</div> -->
      <div class="item-drawer-info-block">
        <dl>
          <div *ngFor="let item of getDrawerItemDetails()" fxLayout="row" fxLayoutAlign="space-between start">
            <dt>{{ item.dt }}</dt>
            <dd>{{ item.dd }}</dd>
          </div>
        </dl>
      </div>
      <mat-divider></mat-divider>
      <div class="item-drawer-info-block">
        <div *ngFor="let item of getDrawerPriceDetails()">
          <div *ngIf="item.price != null" fxLayout="row" fxLayoutAlign="space-between start">
            <div>{{ item.title }}</div>
            <div>${{ item.price }}</div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div *ngIf="canShowStockOnHands()" class="item-drawer-info-block">
        <div>
          <!-- <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>Available In Store</div>
            <div>
              <mat-checkbox>{{ drawerContent.Status === 1 ? 'Yes' : 'No' }}</mat-checkbox>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>In Stock</div>
            <div>
              <mat-checkbox>{{ drawerContent.Availability === 1 ? 'In Stock' : 'Out of Stock' }}</mat-checkbox>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>Days Left Until In Stock</div>
            <div>
              <span>{{ getDaysRemaining(drawerContent.stockAvailabilityDate) }}</span>
            </div>
          </div> -->
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>Stock On Hand</div>
            <div *ngIf="!drawerContent.editStock else stockInput" (click)="toggleEditStock(drawerContent)"
              class="edit-stock-display">{{ drawerContent.stockOnHand }}</div>
            <ng-template #stockInput>
              <input [(ngModel)]="drawerContent.stockOnHand" #crtl="ngModel" type="number" #editStockRef
                class="price-input price-input--stock" (blur)="toggleEditStock(drawerContent)">
            </ng-template>
          </div>

        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="mm-text-center no-catalogue" *ngIf="!loading && notFound else found">
      <img src="assets/images/no-program.png" alt="">
      <h1 class="mat-h2 mm-mb-1">No Catalogue Available</h1>
      <p class="mm-mb-1" *ngIf="jwtAuth.isPharmacyUser(); else notAdmin">You don't seem to have your own
        catalogue.<br>If you would like to have your own catalogue, please contact support <a
          class="mm-link mm-text-accent" [routerLink]="['/support']">here</a>.</p>

      <ng-template #notAdmin>
        <p class="mm-mb-1">{{chosenPharmacy.locationname ?? 'This store'}} doesn't seem to have its own catalogue.</p>
        <p *ngIf="jwtAuth.isBusiness()">If you would like {{chosenPharmacy.locationname ?? 'this store'}} to
          have their own catalogue, please contact support <a class="mm-link mm-text-accent"
            [routerLink]="['/support']">here</a>.</p>
        <p *ngIf="jwtAuth.isAdmin()">You can change this by updating their catalogue tier <a
            class="mm-link mm-text-accent" [routerLink]="['/stores/store-detail']"
            [queryParams]="{location: chosenPharmacy.locationid}">here</a>.</p>
        <button (click)="openListPharmaciesDialog(pharmacies)" mat-stroked-button
          class="mm-mt-3 mm-bg-accent mm-text-white">Change Pharmacy</button>
      </ng-template>
    </div>

    <ng-template #found>
      <div class="container">
        <h1 *ngIf="isAdmin() || isBusiness()" fxLayout="row" class="mat-h1" fxLayoutAlign="center center">
          {{ chosenPharmacy.locationname }}
        </h1>
        <div fxLayout="row" fxLayoutAlign="space-between" class="mm-mb-4">
          <mat-form-field class="search-item-bar" appearance="outline" [floatLabel]="false">
            <input matInput type="text" placeholder="Search items..." #searchbar [formControl]="search">
            <mat-spinner id='search-spinner' *ngIf='searching' mode="indeterminate"></mat-spinner>
          </mat-form-field>

          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <ng-container *ngFor="let button of buttons">
              <button mat-stroked-button (click)="button.action()" [ngClass]="{
                    'mm-bg-accent': button.isActive(),
                    'mm-text-white': button.isActive()
                  }">{{ button.label }}</button>
            </ng-container>

            <button *ngIf="isAdmin() || (!isAdmin() && user()?.location?.uber_store_id)" mat-flat-button
              class="mm-bg-orange mm-text-white" (click)="syncCatalogueWithUber()">Sync With Uber</button>

            <mat-form-field appearance="outline">
              <mat-label>Categories</mat-label>
              <mat-select class="categories-select" (selectionChange)="categoryFilter()" placeholder="Categories"
                multiple [formControl]="categorySelect">
                <mat-option *ngFor="let category of categories1" [value]="category">{{ category }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <button mat-flat-button class="mm-bg-grey-blue mm-text-white mm-mr-2"
              (click)="$event.stopPropagation(); showMoreActions=!showMoreActions;">
              <span>More</span>
              <mat-icon class="mm-mb-1">keyboard_arrow_down</mat-icon>
            </button>
            <ul *ngIf="showMoreActions" class="vieworder-menu">
              <li *ngFor="let menuItem of menuItems"
                [ngClass]="{'vieworder-sub-menu-trigger': menuItem.sub.length > 0}">
                <ng-container *ngIf="menuItem.sub.length == 0; else hasSubMenu">
                  <button mat-menu-item (click)="menuItemAction(menuItem.name)" [style]="{'color': menuItem.color}">{{
                    menuItem.name }}</button>
                </ng-container>
                <ng-template #hasSubMenu>
                  <ng-container>
                    <button mat-menu-item (click)="$event.stopPropagation();">
                      <span>{{ menuItem.name }}</span>
                      <mat-icon style="color: rgb(41, 41, 41)">keyboard_arrow_right</mat-icon>
                    </button>
                    <ul class="vieworder-menu vieworder-sub-menu">
                      <li *ngFor="let subMenu of menuItem.sub">
                        <button mat-menu-item
                          (click)="$event.stopPropagation();subMenuAction(subMenu);showMoreActions = false;">{{ subMenu
                          }}</button>
                      </li>
                    </ul>
                  </ng-container>
                </ng-template>
              </li>
            </ul>
            <button mat-flat-button class="mm-bg-grey-blue mm-text-white mm-mr-2" *ngIf="isAdmin() || isBusiness()"
              (click)="openListPharmaciesDialog(pharmacies)">Change Pharmacy</button>
          </div>
        </div>
        <mat-table #table matSort [dataSource]="dataSource">
          <ng-container matColumnDef="image">
            <mat-header-cell *matHeaderCellDef class="small">Image</mat-header-cell>
            <mat-cell (click)="openDrawer(row)" *matCellDef="let row; let j = index" class="product-img-cell small">
              <div class="product-img" [ngStyle]="{'background-image': 'url(' + row.PhotoID_s3 + ')'}"></div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="productName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell (click)="openDrawer(row)" *matCellDef="let row" class="mm-flex-start"> {{row.ItemName}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="small">
              <span>Price</span>
              <mat-icon class="mm-text-primary" *ngIf="canShowPriceInfo" style="cursor: pointer;"
                (click)="openPharmacyPriceInfoDialog()">info</mat-icon>
            </mat-header-cell>
            <mat-cell class="small" *matCellDef="let row; let i = index">
              <div [ngClass]="{'price-display-active': inactive==false, 'price-display-inactive': inactive==true}"
                [disabled]="disableRow && !row.inactiveItem==0" *ngIf="!row.editPrice else priceInput"
                (click)="openDialog($event, row)">${{ row.standardPrice }}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ean">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="small">EAN</mat-header-cell>
            <mat-cell *matCellDef="let row" class="small" (click)="stopPropagation($event)">
              <div #imageMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">{{row.EAN}}</div>
              <mat-menu #menu="matMenu">
                <button (click)="getUID('EAN', row.EAN)" mat-menu-item>Copy EAN</button>
                <button (click)="getUID('Medmate Item Code', row.MedmateItemCode)" mat-menu-item>Copy Item Code</button>
              </mat-menu>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="stockOnHand" *ngIf="canShowStockOnHands()">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="small">Stock On Hand</mat-header-cell>
            <mat-cell class="small" *matCellDef="let row; let i = index" (change)="updateProduct(row)">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div *ngIf="!row.editStock else stockInput" (click)="row.editStock=true;" class="edit-stock-display">{{
                  row.stockOnHand }}</div>
                <ng-template #stockInput>
                  <input [(ngModel)]="row.stockOnHand" #crtl="ngModel" type="number" autofocus
                    class="price-input price-input--stock" (blur)="row.editStock=false;">
                </ng-template>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="inStock">
            <mat-header-cell *matHeaderCellDef mat-sort-header (sortChange)="sortInStock()" class="small">Is it in
              stock?</mat-header-cell>
            <mat-cell class="small" *matCellDef="let row">
              <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center center">
                <mat-slide-toggle [checked]="row.Availability > 0 || row.Availability === null"
                  [disabled]="!row.Status || disableRow || row.inactiveItem == 1"
                  (change)="updateToggleField($event.checked, row, 'Availability')" (click)="stopPropagation($event)"
                  [ngClass]="{'out-of-stock': row.Availability === 0}">{{ getStockValue(row.Availability)
                  }}</mat-slide-toggle>
                <a mat-flat-button (click)="stopPropagation($event)" class="mm-text-white mm-mt-8" color="primary"
                  [href]="'https://www.sigmaconnect.com.au/search/?text=' + row?.EAN" target="_blank"
                  rel="noopener noreferrer">Order Now</a>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="isTopSeller">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="small">Is top seller?</mat-header-cell>
            <mat-cell class="small" *matCellDef="let row">
              <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center center">
                <mat-slide-toggle [checked]="row.TopSellerOrder==1"
                  [disabled]="!row.Status || disableRow || row.inactiveItem == 1"
                  (change)="updateToggleField($event.checked, row, 'TopSellerOrder')"
                  (click)="stopPropagation($event)"></mat-slide-toggle>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Status">
            <mat-header-cell *matHeaderCellDef class="small">Status</mat-header-cell>
            <mat-cell class="small" *matCellDef="let row">
              <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center center">
                <mat-slide-toggle [checked]="row.Status==1"
                
                  (change)="isPharmacySellingItem($event.checked, row, 'Status')"
                  (click)="stopPropagation($event)"></mat-slide-toggle>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="createdAt">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="small">Created At</mat-header-cell>
            <mat-cell (click)="openDrawer(row)" *matCellDef="let row" class="small">
              {{ row.created_at | date:'dd-MMM-yyyy' }}<br>{{ row.created_at | date:'HH:mm:ss' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="updatedAt">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="small">Updated At</mat-header-cell>
            <mat-cell (click)="openDrawer(row)" *matCellDef="let row" class="small">
              {{ row.updated_at | date:'dd-MMM-yyyy' }}<br>{{ row.updated_at | date:'HH:mm:ss' }}
            </mat-cell>
          </ng-container>

          <mat-header-row class="fix-header" *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

          <ng-container matColumnDef="groupHeader">
            <mat-cell class="mm-flex-start category-heading-cell" *matCellDef="let groupBy">
              <div class="category-heading mm-bg-primary mm-text-white">{{groupBy.name}}</div>
            </mat-cell>
          </ng-container>
          <mat-row class="category-row" *matRowDef="let row; columns: ['groupHeader']; when: isGroup">
          </mat-row>
        </mat-table>
      </div>
      <!-- <mat-paginator *ngIf='!inactive' class="sticky-footer mm-bg-primary mm-text-white" [ngStyle]="{'display': dataSource.data.length > 0 ? 'block' : 'none'}" [length]="dataSource.data.length" pageSize="30"></mat-paginator> -->
      <div *ngIf="dataSource.data === 0 && !loading">
        <div>
          This Catalogue Is Empty...
        </div>
      </div>
      <mat-toolbar #paginator class="custom-paginator-container" *ngIf="length >= 0">
        <span class="grow">Page {{pageIndex}} of {{lastPage}}</span>
        <div class="grow">
          <div *ngIf="length==0">No Products To Be Displayed</div>
          <span class="grow">{{ from }} - {{ to }} of {{ length }} Results</span>
          <span class="mobile-label"></span>
        </div>
        <span class="custom-paginator_options-hold">
          <mat-form-field appearance="standard" style="width: 75px">
            <mat-select (selectionChange)="pageSizeChange($event)" [(value)]="pageSize">
              <mat-option *ngFor="let option of [50, 100, 500, 1000]" [value]="option">{{ option }}</mat-option>
            </mat-select>
          </mat-form-field>
          <span>
            <button disableRipple mat-icon-button [disabled]="pageIndex <= 1" (click)="getFirstPage()">
              <mat-icon>first_page</mat-icon>
            </button>
            <button disableRipple mat-icon-button [disabled]="pageIndex <= 1" (click)="getPreviousPage()">
              <mat-icon>chevron_left</mat-icon>
            </button>
            <button disableRipple mat-icon-button [disabled]="this.to >= length" (click)="getNextPage()">
              <mat-icon>chevron_right</mat-icon>
            </button>
            <button disableRipple mat-icon-button [disabled]="this.to >= length" (click)="getLastPage()">
              <mat-icon>last_page</mat-icon>
            </button>
          </span>
        </span>
      </mat-toolbar>
    </ng-template>
  </mat-drawer-content>
</mat-drawer-container>
<mat-spinner id='loading-spinner' *ngIf="loading" diameter="50"></mat-spinner>