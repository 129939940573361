import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { config } from 'config';
import { HttpClient } from '@angular/common/http';
import { JwtAuthService } from '../auth/jwt-auth.service';
@Injectable({
  providedIn: 'root'
})
export class ListPharmaciesService {
  pharmacies
  constructor(private http: HttpClient, private jwtAuth: JwtAuthService) { }

  getPharmacies(): Observable<any> {

    let api = 'getListofActivePharmacies'
    if (this.jwtAuth.isBusiness()) {
      api += '?statuses=1,2,4,5&business_id=' + this.jwtAuth.getUser().businessid
    }

    return this.http.get(config.apiUrl + api, {
      headers: {
        'Authorization': `Bearer ${this.jwtAuth.getJwtToken()}`
      }
    })
  }

  getPharmaciesByLocation(id) {
    return this.http.get<any>(config.apiUrl1 + 'getPharmaciesByLocation', {
      params: {
        locationid: id
      }
    })
  }

}
